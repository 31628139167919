import React from 'react'
import HospitalBilling from '../Components/HospitalBilling/HospitalBilling'

const HospitalPage = () => {
  return (
    <div>
      <HospitalBilling />
    </div>
  )
}

export default HospitalPage
