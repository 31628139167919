import React from 'react'
import vission from '../../../assets/vission.png'
const Vission = () => {
  return (
    <>
    </>
//     <div className='diff_bg p-5'>
//        <div className='container'>
//         <div className='row align-items-center'>
//             <div className='col-lg-9 col-12'>
// <h5>Vision</h5>
// <p className='line'>Our vision is to advance the industry’s standard of service by providing new, innovative <br /> solutions that help our clients achieve their highest levels of success and efficiency.</p>
//             </div>
//             <div className='col-lg-3 col-12'>
// <img src={vission} alt="" className='img-fluid'/>
// </div>
//         </div>
//        </div>
//     </div>
  )
}

export default Vission
