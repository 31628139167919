import React from 'react'
import mission from '../../../assets/mission.jpg'
import './mission.css'
const Mission = () => {
  return (
    <div className='container mt-5 mb-5'>
      {/* <div className='row align-items-center'>
        <div className='col-lg-3 col-12'>
            <img src={mission} alt="" className='img-fluid'/>
        </div>
        <div className='col-lg-9 col-12'>
<h1 className='clr'>Mission</h1>
<p className='mt-2 line'>At The sure ways solutions, we know that each client’s needs are unique. Our mission is to provide exceptional, tailored support that fosters healthy practices and strong revenue management systems.</p>
        </div>
    </div> */}
    </div>
  )
}

export default Mission
