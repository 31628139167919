import React from 'react'
import Recovery from '../Components/ArRecovery/Recovery'

const RecoveryPage = () => {
  return (
    <div>
      <Recovery />
    </div>
  )
}

export default RecoveryPage
