import React from 'react'
import Special from '../Components/Specialities/Special'

const SpecialitiesPage = () => {
  return (
    <div>
      <Special />
    </div>
  )
}

export default SpecialitiesPage
