import React from 'react'
import ImagingCenter from '../Components/ImageBilling/ImagingCenter'

const ImageBillingPage = () => {
  return (
    <div>
      <ImagingCenter />
    </div>
  )
}

export default ImageBillingPage
