import React from 'react'
import value from '../../../assets/value.svg'
const Value = () => {
        return (
                <>
                        <div className='container mt-5'>
                                {/* <div className='row'>
        <h2 className='clr text-center'>OUR COMPANY VALUES</h2>
        <p className='text-center'>The sure ways solutions’s core values are reflected in our dedication to delivering the most efficient solutions for medical billing and revenue cycle <br /> management.</p>
     <div className='col-lg-12 d-lg-flex d-block gap-3'>
        <div className='col-lg-6 col-12 revenue_box'>
<img src={value} alt="" className='img-fluid value'/>
<h5>Expertise</h5>
<p>Our certified medical billing specialists and technical analysts are highly trained and adept in all areas of medical claims billing. The sure ways solutions provides comprehensive, continuous training so our team is always up to date on current regulations and technologies.</p>
        </div>
        <div className='col-lg-6 col-12 revenue_box'>
<img src={value} alt="" className='img-fluid value'/>
<h5>Expertise</h5>
<p>Our certified medical billing specialists and technical analysts are highly trained and adept in all areas of medical claims billing. The sure ways solutions provides comprehensive, continuous training so our team is always up to date on current regulations and technologies.</p>
        </div>
       
     </div>
     <div className='col-lg-12 mt-lg-3 mt-0 d-lg-flex d-block gap-3'>
        <div className='col-lg-6 col-12 revenue_box'>
<img src={value} alt="" className='img-fluid value'/>
<h5>Expertise</h5>
<p>Our certified medical billing specialists and technical analysts are highly trained and adept in all areas of medical claims billing. The sure ways solutions provides comprehensive, continuous training so our team is always up to date on current regulations and technologies.</p>
        </div>
        <div className='col-lg-6 col-12 revenue_box'>
<img src={value} alt="" className='img-fluid value'/>
<h5>Expertise</h5>
<p>Our certified medical billing specialists and technical analysts are highly trained and adept in all areas of medical claims billing. The sure ways solutions provides comprehensive, continuous training so our team is always up to date on current regulations and technologies.</p>
        </div>
       
     </div>
  
      </div> */}
                        </div>
                        {/* <div className='diff_bg mt-5'>
       <div className='container'>
           <div className='row'>
           <div className='col-lg-12 p-5'>
          <p>The sure ways solutions offers comprehensive professional billing services and best-practice process management to shorten your reimbursement cycle, increase revenue streams, and maximize profitability.
It’s difficult to remain completely focused on patient care when you’re worrying about your bottom line. If you’re spending valuable resources trying to diagnose finances rather than patients, then perhaps it’s time to schedule a check-up for your current medical billing methods.

Contact Us Now</p>
<button className='btn btn_demo text-white'>Contact Us Now</button>
       </div>
           </div>
 
       </div>
 
    </div> */}
                </>

        )
}

export default Value
